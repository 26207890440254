import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"


import PostLink from "../components/post-link"
import PostCard from "../components/post-link-cards"
import PostImage from "../components/post-image"
import Content from "../components/post-content"

import { data } from "autoprefixer";
import { siteMetadata } from "../../gatsby-config";

const ProductPage = ({data: {allMarkdownRemark: { edges }, },}) => {

    const pictures = edges
    .filter(edge => edge.node.frontmatter.catagory==="Impressionen" && edge.node.frontmatter.page==="Dienstleistungen") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostImage key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

    const welcomeText = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="ankauf") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-900"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

   
  return (
    <Layout>
      <Helmet>
        <title>Haushaltsauflösungen | Schatzkammer Lübeck</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Abwicklung und kostenlose Beratung bei: Haushaltsauflösungen | Nachlassverwertungen | Entrümpelungen | Insolvenzverwertungen | Räumungen von Geschäftsräumen und sonstigen Immobilien" />
        <meta name="author" content="Schatzkammer Lübeck" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <section class=" py-8 ">
  <div class="container max-w-5xl mx-auto m-8">
    
  <h1 class="font-serif w-full my-2 md:text-5xl font-bold leading-tight text-center text-gray-800" >Haushaltsauflösung und Nachlassverwertung</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto skBrown w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>   
    {welcomeText}
    </div>

      </section>

      <section class="py-4">

      <div class="grid md:grid-cols-4 grid-cols-1 mx-auto gap-2 m-8">  
      {pictures}
      </div>
      </section>



    </Layout>
  )


}

export default ProductPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            catagory
            page
            metaDescription
          }
          html
        }
      }
    }
  }
`
